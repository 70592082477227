
import { useEffect, useState, useLayoutEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';

import * as API from "../utils/utils";
import Button from "../components/Button";
import Tokens from "../components/Tokens";
import NFTs from "../components/NFTs";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import cap from "../images/cap-hoodie-01-md.webp";
import hoodie from "../images/hoodie.webp";
import Header from "../components/Header";
import MainPage from "../components/MainPage";
import ProcessingModal from "../components/ProcessingModal.js";

export default function Claim() {

    const wallet = useWeb3React();
    const [status, setStatus] = useState(""); // "success", "error", "processing"
    const [txHash, setTxHash] = useState("");
    const [isConnected, setIsConnected] = useState(false);
    const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [tokensToMint, setTokensToMint] = useState([]);
    const [tokensLoading, setTokensLoading] = useState(true);

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useLayoutEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const navigate = useNavigate();


    const doClaim = async () => {
        if (tokensToMint.length > 0)
            setIsProcessingModalOpen(true);
    }

    return (
        <div className="h-full w-full relative">
            {isProcessingModalOpen && (
                <ProcessingModal
                    setIsProcessingModalOpen={setIsProcessingModalOpen}
                    token={tokensToMint[0]}
                />
            )}
            <div className="">
                <Header
                    menuItems={['Main Website', 'Opensea Collection']}
                    rightArea={null}
                    showSocial={true}
                    externalLink={true}
                    routes={['https://drugreceipts.com/', 'https://opensea.io/collection/drugreceipts']}
                />

                <MainPage>
                    <div className="flex flex-col w-full justify-center items-center">

                        <div className="flex flex-col md:flex-row w-full mt-10 items-center">
                            <div className="text-white md:pr-12 md2:mt-10 lg:mt-14 md:w-1/2 ">
                                <h1 className="font-pressio-condensed text-5xl font-medium mb-2 ">
                                    DRx  Travel Co. <br />
                                    Hoodie Claim
                                </h1>
                                <p className="font-open-sans text-xl pt-2 font-light">
                                    Avatars shouldn’t get to have all of the fun. As a DRx Travel Co.
                                    Hoodie token holder you are eligible to claim your IRL hoodie to sport
                                    proudly in real life. Let the world know that you are a Drug Snuggler—you
                                    are a wanderer, an explorer, and a knowledge seeker. Dare to stand out.
                                    Make a statement in mind-altering apparel.
                                </p>
                            </div>

                            <div className="md:w-1/2 ">
                                <img src={hoodie} className='w-full max-w-[405px]' />
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row w-full mb-4 items-center ">
                            {dimensions.width > 767 && (
                                <div className="md:w-1/2 md:pl-12">
                                    <img src={cap} className='w-full max-w-[405px]' />
                                </div>
                            )}
                            <div className="text-white md:ml-10 md2:mt-10 lg:mt-28 md:w-1/2 ">
                                <h1 className="font-pressio-condensed text-5xl font-medium mb-2 ">
                                    Claim <br />
                                    Your Hoodie
                                </h1>
                                <p className="font-open-sans text-xl pt-2 font-light">
                                    To access the claim form simply connect your wallet containing  your DRx
                                    Travel Co. Hoodie token below.  If you hold more than one eligible token,
                                    you will have to go through the process more than once. Only one claim
                                    may be processed at a time.
                                </p>

                                <div className="flex items-center flex-wrap w-full h-full pt-4 sm:pt-16">
                                    <div className="absolute top-0 right-1">
                                        {wallet.account}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="w-full rounded-md pt-4 md:pt-0
                        overflow-x-auto md:overflow-x-hidden ">
                            <NFTs className=''
                                tokensToMint={tokensToMint}
                                setTokensToMint={setTokensToMint}
                                isProcessingModalOpen={isProcessingModalOpen}
                            >
                            </NFTs>
                        </div>

                        <div className='flex justify-center mt-5 mb-24 text-[26px] w-[300px]'>
                            <Button className=''
                                clickFunction={() => { doClaim() }}
                            >
                                Proceed to Form
                            </Button>
                        </div>
                    </div>
                </MainPage>
            </div>
        </div>

    )
}