import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { AiFillCloseCircle } from "react-icons/ai";

import * as API from '../utils/utils';
import classNames from "classnames";
import hoodie from '../images/hoodie_sm.png'

export default function NFTs({ tokensToMint, setTokensToMint, isProcessingModalOpen }) {

    const [images, setImages] = useState([]);
    const [selected, setSelected] = useState([]);
    const [unselected, setUnselected] = useState([]);

    const [tokensInWallet, setTokensInWallet] = useState([]);
    const [tokensLoading, setTokensLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [didHaveTokens, setDidHaveTokens] = useState(false);
    const contractAddress = "";
    const wallet = useWeb3React();
    const navigate = useNavigate();

    const getTokensForWallet = useCallback(
        async () => {
            try {
                // : ensure wallet.account is used in prod
                let tokenIds = await API.retrieveValidTokens(wallet.account);
                // let tokenIds = await API.retrieveValidTokens("0x3d330b89305c405e46bf1a14f10e66575179d8a0");
                setTokensInWallet(tokenIds);
                setTokensLoading(false);
            } catch (e) {
                console.error(e.message);
                setTokensLoading(false);
                setErrorMessage(e.message);
            }
        }, [wallet.account, navigate]
    );

    useEffect(() => {
        if (!isProcessingModalOpen) { // could change this to form success only (reduce api call) so it happens before closing (can still unselect token on error)
            setTokensToMint([]);
            getTokensForWallet();
        }
    }, [isProcessingModalOpen]);

    // useEffect(() => {
    //     getTokensForWallet();
    // }, [tokensToMint])

    const getImageUrl = (tokenId) => {
        try {
            const url = API.getImageUrl(tokenId);
            return url;
        } catch (e) {
            console.error(e.message);
            setTokensLoading(false);
            setErrorMessage(e.message);
        }
    }

    function toggleImageSelected(tokenId) {
        let current = [...tokensToMint];
        if (current.includes(tokenId)) current.pop()
        else if (current.length > 0) return;
        else current.push(tokenId);

        setTokensToMint(current);
    }

    return (
        <div className="bg-black/50 w-full overflow-y-auto rounded-md mb-5 p-3 ">
            {tokensInWallet.length === 0 && (
                <div className="flex items-center justify-center h-full text-center text-white">
                    {tokensLoading
                        ? "Loading..."
                        : errorMessage
                            ? `Error: ${errorMessage}`
                            : "You don't have any tokens that are eligible to claim."
                    }
                </div>
            )}
            {tokensInWallet.length > 0 && (
                <div>
                    <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-5 overflow-x-auto 
                        gap-y-2 w-full  text-center place-items-center">
                        {tokensInWallet && (tokensInWallet).map((token) => (
                            <div key={token}
                                className={'cursor-pointer relative overflow-hidden min-w-1/4 max-w-[130px]'
                                    + classNames({
                                        " border border-white rounded-md":
                                            tokensToMint.includes(token)
                                    })}
                                onClick={() => toggleImageSelected(token)}>

                                <img src={hoodie} alt={`Hoodie #${token}`}
                                    className={'rounded-md '}
                                />
                                <p className="text-white font-open-sans text-opacity-80 font-extralight text-sm">
                                    Hoodie #{token}
                                </p>
                                <AiFillCloseCircle
                                    className={`absolute top-1 right-1 w-8 h-8 text-white opacity-100
                                        ${tokensToMint.includes(token) ? 'block' : 'hidden'} `} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

// <AssetCard
//     asset={asset}
//     key={`${asset.tokenId}`}
// />