import { ethers } from "ethers";
import abi from '../abis/abi';

const CONTRACT_ADDRESS = "0x03Ade60cE17FDefaCD5A28b1059aFC5760397D3B";
// const API_URL = 'http://localhost:3001';
// const API_URL = 'https://drx-hoodie-claim.fluf.dev'; // dev API  
const API_URL = 'https://drx-hoodie-claim.srv.fluf.app'; // todo: use deployed PROD API  

const getHeaders = () => {
    return {
        headers: {
            "Content-Type": "application/json",
        },
    };
};

const extractBody = async (res) => {
    if (!res.ok) {
        throw new Error(await res.text());
    } else {
        return res.json();
    }
};


let provider, signer, contractProvider;
if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
    // contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
} else {
    // INFURA FALLBACK RPC HERE
    let fallbackProvider = ethers.getDefaultProvider("homestead", {});
    // contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, fallbackProvider);
}

export const updateProviderAndSigner = (library) => {
    if (library) {
        provider = new ethers.providers.Web3Provider(library.provider);
    } else {
        provider = new ethers.providers.Web3Provider(window.ethereum);
    }
    signer = provider.getSigner();
    contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
};


export const getImageUrl = (tokenId, rand = false) => {
    const url = ``;
    return url;
}

export const retrieveValidTokens = async (walletAddress) => {
    return await fetch(`${API_URL}/api/validtokens`, {
        method: 'POST',
        ...getHeaders(),
        body: JSON.stringify({ walletAddress })
    })
        .then((response) => extractBody(response))
        .catch((e) => {
            console.error(e);
        });
}


// export const checkWhitelisted = async (walletAddress) => {
//     // return {whitelisted: true}
//     return await fetch(`${API_URL}/api/iswhitelisted`, {
//         method: "POST",
//         ...getHeaders(),
//         body: JSON.stringify({ walletAddress })
//     }).then((response) => extractBody(response));
// };


export const signMessagePublic = async (walletToAuthenticate, provider, context) => {
    let message = `This signature is to prove that you are the true owner of this wallet.`;
    let signature;

    provider = provider.toLowerCase()

    if (provider === "walletlink") {
        await sleep(400);
    }

    if (provider === "walletconnect" || provider === "walletlink") {
        signature = await context.library.provider.send("personal_sign", [
            ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
            walletToAuthenticate.toLowerCase(),
        ]);
        console.log(`${walletToAuthenticate}`)
    } else {
        signature = await signer.signMessage(message);
    }

    return { message, signature };
};


export const submitTicketForm = async (
    firstName,
    lastName,
    email,
    phoneNumber,
    hoodieSize,
    address1,
    address2,
    region,
    city,
    postalCode,
    country,
    walletAddress,
    tokenId,
    contractAddress,
    message,
    signature,
) => {


    return await fetch(`${API_URL}/api/submitform`, {
        method: "POST",
        ...getHeaders(),
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber,
            hoodieSize,
            address1,
            address2,
            region,
            city,
            postalCode,
            country,
            walletAddress,
            tokenId,
            contractAddress,
            message,
            signature,
        }),
    }).then((response) => extractBody(response));
};

export const getSizeStock = async () => {
    return await fetch(`${API_URL}/api/sizesremaining`, {
        method: "GET",
        ...getHeaders(),
    }).then((response) => extractBody(response));
}

const sleep = async (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};
