import { useState, useEffect } from 'react';

import { ReactComponent as Close } from "../images/X.svg";
import cap from "../images/cap-hoodie-01-md.webp";

import WalletProtected from '../layout/WalletProtected'
import Wallet from "../pages/Wallet";

export default function ConnectModal({ closeModal }) {
    return (
        <div className="fixed flex flex-wrap justify-center items-center 
        top-0 left-0 bottom-0 -right-0 z-10 
        bg-opacity-60 bg-black backdrop-blur-sm w-screen h-full">


            <div className="flex flex-col fixed justify-center items-center p-5 
            w-[80%] md:w-[50%] h-[85%] xl:w-[40%] 3xl:h-[60%] bg-drbg bg-cover rounded-3xl ">

                <div onClick={closeModal}
                    className="flex fixed sm:absolute right-0 top-0 p-3 m-2 
                    justify-center items-center h-14 w-14 rounded-full
                    hover:cursor-pointer transform hover:border-4 ">
                    <Close className="" />
                </div>

                <div className="flex flex-col text-white h-full justify-center text-center items-center">
                    <h1 className="font-pressio-condensed text-4xl sm:text-5xl mb-auto">CONNECT WALLET</h1>
                    <img className="h-[30%] sm:h-[40%] max-h-[40%] mx-auto my-8 xs:my-6" src={cap} />

                    <div className=' w-[95%] mt-auto'>
                        <WalletProtected>
                            <Wallet />
                        </WalletProtected>
                    </div>
                </div>
            </div>
        </div>
    )
}